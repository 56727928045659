import { useEffect, useState } from 'react';
import { decode } from 'html-entities';
import React from 'react';
import BtnChromaBorder from '../shared/BtnChromaBorder';

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [formStatus, setFormStatus] = useState(status);

  useEffect(() => {
    let timeoutID = null;
    setFormStatus(status);
    if (status === 'success' && !error) {
      setEmail('');
    }
    timeoutID = setTimeout(() => setFormStatus(null), 2000);
    return () => {
      clearTimeout(timeoutID);
    };
  }, [status]);
  /**
   * Handle form submit.
   *
   * @return {{value}|*|boolean|null}
   */
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      // @ts-ignore
      setError('Please enter a valid email address');
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    // On success return true
    // @ts-ignore
    return email && email.indexOf('@') > -1 && isFormValidated;
  };

  /**
   * Handle Input Key Event.
   *
   * @param event
   */
  const handleInputKeyEvent = (event) => {
    setError(null);
    // Number 13 is the "Enter" key on the keyboard
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      handleFormSubmit();
    }
  };

  /**
   * Extract message from string.
   *
   * @param {String} message
   * @return {null|*}
   */
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split('-') ?? null;
    if ('0' !== result?.[0]?.trim()) {
      return decode(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? decode(formattedMessage) : null;
  };

  const changeHandler = (event) => setEmail(event.target.value);

  return (
    <>
      <div className='flex flex-col md:flex-row newsletter-input-fields'>
        <div className='flex flex-col w-100 md:w-8/12 md:flex-row justify-items-stretch'>
          <input
            // @ts-ignore
            onChange={changeHandler}
            type='email'
            placeholder='Your email'
            className='w-full px-4 py-2 bg-white border text-neutral-800 border-slate-300 focus:outline-none focus:border-sky-500 focus:ring-sky-500 md:text-lg'
            onKeyUp={handleInputKeyEvent}
            value={email}
          />
        </div>
        <div className='w-full mt-4 button-wrap wp-block-button md:mt-0 md:w-4/12'>
          <BtnChromaBorder
            onClick={handleFormSubmit}
            className="wp-block-button__link p-[3px]   border-2 uppercase font-['Now'] font-black text-xl html w-full"
          >
            <span className='block w-full h-full p-3 bg-black'>
              <span className='relative z-10'> STAY TUNED</span>
            </span>
          </BtnChromaBorder>
        </div>
      </div>
      <div className='newsletter-form-info'>
        {formStatus === 'sending' && <div>Sending...</div>}
        {formStatus === 'error' || error ? (
          <div
            className='newsletter-form-error'
            dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
          />
        ) : null}
        {formStatus === 'success' &&
          formStatus !== 'error' &&
          !error &&
          'Will keep you posted'}
      </div>
    </>
  );
};

export default NewsletterForm;
