import { useReducer, useContext, createContext, useEffect } from 'react';
import { ACTIONS } from './consts';
import { useQuery } from '@apollo/client';
import { GET_GLOBAL_CONFIG } from '../../data/global';
import { GET_IMAGES_CONTACT } from '../../data/pages';

const initialState = {
  isMenuOpen: true,
  postsPerPage: 40,
  isRecivedImageShowing: true,
  isOverlayOpen: false,
  images: [],
};

const UIDispatchContext = createContext();
const UIStateContext = createContext(initialState);
UIDispatchContext.displayName = 'UI_Dispatch_Context';
UIStateContext.displayName = 'UI_State_Context';

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_MENU_STATE:
      return {
        ...state,
        isMenuOpen: action.payload,
        isRecivedImageShowing: false,
      };
    case ACTIONS.SET_MENU_STATE_OPEN:
      return {
        ...state,
        isMenuOpen: true,
        isRecivedImageShowing: false,
      };
    case ACTIONS.SET_MENU_STATE_CLOSE:
      return {
        ...state,
        isMenuOpen: false,
        isRecivedImageShowing: false,
      };
    case ACTIONS.GLOBAL_POSTS_PER_PAGE:
      return { ...state, postsPerPage: action.payload };
    case ACTIONS.SET_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    case ACTIONS.SET_RECIVED_IMAGE:
      return {
        ...state,
        isRecivedImageShowing: action.payload,
      };
    case ACTIONS.SET_OVERLAY:
      return {
        ...state,
        isOverlayOpen: action.payload,
      };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};

export const UIProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, 0);
  const { data } = useQuery(GET_GLOBAL_CONFIG);
  const { data: contactPage } = useQuery(GET_IMAGES_CONTACT, {
    variables: { id: '/contact-me' },
  });

  useEffect(() => {
    dispatch({
      type: ACTIONS.GLOBAL_POSTS_PER_PAGE,
      payload: data?.allSettings.readingSettingsPostsPerPage,
    });
    dispatch({
      type: ACTIONS.SET_IMAGES,
      payload: contactPage?.page?.PageContactme.galleryThankYouImages,
    });
  }, []);
  useEffect(() => {
    dispatch({
      type: ACTIONS.SET_IMAGES,
      payload: contactPage?.page?.PageContactme.galleryThankYouImages,
    });
  }, [state.isRecivedImageShowing, contactPage]);

  return (
    <UIDispatchContext.Provider value={dispatch}>
      <UIStateContext.Provider value={state}>
        {children}
      </UIStateContext.Provider>
    </UIDispatchContext.Provider>
  );
};

export const useUIDispatchContext = () => useContext(UIDispatchContext);
export const useUIStateContext = () => useContext(UIStateContext);
