import { motion, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import Overlay from '../Navegation/Overlay';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const variants = {
  fadeIn: {
    opacity: 0,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
  inactive: {
    opacity: 1,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/animated-page-transitions-in-nextjs
 */
const PageTransition = ({ children }) => {
  const { asPath } = useRouter();

  return (
    <div className='overflow-hidden bg-black'>
      <Overlay />
      <AnimatePresence initial={false} mode='wait'>
        <motion.div
          key={uuidv4()}
          variants={variants}
          initial='fadeIn'
          animate='inactive'
          exit='fadeOut'
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default PageTransition;
