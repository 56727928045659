import React from 'react';
import classNames from 'classnames';
import styles from './BtnChromaBorder.module.css';
import Link from 'next/link';

const ClonnedComponent = (props) => {
  const { children, href = null, onClick = null, className } = props;

  const component = href ? 'a' : 'button';

  return !href ? (
    React.createElement(
      component,
      { ...props, className, href, onClick },
      children
    )
  ) : (
    <Link scroll={false} href={href} legacyBehavior>
      {React.createElement(
        component,
        { ...props, className, href, onClick },
        children
      )}
    </Link>
  );
};

const BtnChromaBorder = ({ onClick, className, children, href }) => {
  const classes = classNames(styles.btnChromaBorder, className);

  return (
    <ClonnedComponent onClick={onClick} href={href} className={classes}>
      {children}
    </ClonnedComponent>
  );
};

export default BtnChromaBorder;
