import React, { useEffect } from 'react';
import { useUIDispatchContext, useUIStateContext } from '../UIContext';
import { ACTIONS } from '../UIContext/consts';
import { motion } from 'framer-motion';

const imageAnimation = {
  closed: {
    translateY: -1000,
    opacity: 0,
    transition: {
      ease: 'easeOut',
    },
  },
  opened: {
    translateY: 0,
    opacity: 1,
    transition: {
      ease: 'easeIn',
    },
  },
};

const bgAnimation = {
  closed: {
    opacity: 0,
    transition: {
      ease: 'easeOut',
    },
  },
  opened: {
    opacity: 1,
    transition: {
      ease: 'easeIn',
    },
  },
};

const MessageRecivedImage = () => {
  const state = useUIStateContext();
  const dispatch = useUIDispatchContext();
  const images = state.images || [];
  const [image, setImage] = React.useState(images[0]);

  useEffect(() => {
    setImage(images[Math.floor(Math.random() * images.length)]);
  }, [state.isRecivedImageShowing]);

  const closeHandler = () => {
    dispatch({ type: ACTIONS.SET_RECIVED_IMAGE, payload: false });
  };

  return (
    <motion.div
      className={`w-screen h-screen z-50 justify-center items-center flex flex-col bg-black/60 ${
        state.isRecivedImageShowing
          ? 'pointer-events-auto fixed'
          : 'pointer-events-none absolute'
      }`}
      onClick={closeHandler}
      animate={state.isRecivedImageShowing ? 'opened' : 'closed'}
      variants={bgAnimation}
    >
      <motion.div
        animate={state.isRecivedImageShowing ? 'opened' : 'closed'}
        variants={imageAnimation}
        className='fixed z-50 flex flex-col items-center justify-center w-screen h-screen'
      >
        <img
          src={image?.sourceUrl}
          alt='message recieved'
          className='w-11/12 h-auto border-8 border-b-0 border-white md:w-1/3 drop-shadow-card translate-z-0'
        />
        <div className='relative z-50 block w-11/12 py-4 text-center text-black bg-white font-now md:w-1/3'>
          <h4 className='py-2 text-5xl uppercase '>Message Received!</h4>
          <p className='text-2xl font-regular'>
            I&apos;ll get back to you shortly 🙂
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default MessageRecivedImage;
