export const ANIMATIONS = {
  menuParent: {
    open: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.2,
        when: 'afterChildren',
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
        when: 'afterChildren',
        delayChildren: 1,
      },
    },
  },
  background: {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        ease: 'easeOut',
        duration: 0.4,
      },
    },
    closed: {
      opacity: 0,
      y: -1500,
      transition: {
        ease: 'easeOut',
        delay: 1.5,
        duration: 0.7,
      },
    },
  },
};
