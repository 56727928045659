import React, { useEffect } from "react";
import Nav from "../Navegation/index";
import Footer from "../Footer";
import ScrollToTop from "../ScrollToTop";
import { ApolloProvider } from "@apollo/client";
import { getApolloClient } from "../../lib/apollo-client";
import PageTransition from "../PageTransition";
import { UIProvider } from "../UIContext";
import MessageRecivedImage from "../MessageRecivedImage";

export const Layout = (props) => {
  const { children } = props;
  const apolloClient = getApolloClient();

  return (
    <ApolloProvider client={apolloClient}>
      <UIProvider>
        <PageTransition>
          <MessageRecivedImage />
          <Nav />
          <ScrollToTop />
          <main className='static'>{children}</main>
          <Footer />
        </PageTransition>
      </UIProvider>
    </ApolloProvider>
  );
};
