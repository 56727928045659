import {
  HOMEPAGE,
  PAGE_MENU,
  FOOTER_DETAILS,
  ABOUT_ME_HOME_EXCERPT,
  CONTACT_PAGE,
  GET_URLS,
  ABOUT_ME_HOME,
} from '../data/pages';
import { getApolloClient } from '../lib/apollo-client';
const apolloClient = getApolloClient();

export async function getAboutMeHomeNew() {
  let pageData;
  // let seoData; implement SEO

  try {
    pageData = await apolloClient.query({
      query: ABOUT_ME_HOME,
      fetchPolicy: 'no-cache',
      variables: {
        id: '/',
      },
    });
  } catch (e) {
    console.log(
      `[pages][getPageByUri] Failed to query page data: ${e.message}`
    );
    throw e;
  }

  return {
    page: {
      content: pageData?.data?.page?.content,
      title: pageData?.data.page.title,
      og: pageData?.data?.page?.seo,
    },
    aboutMeFields: pageData?.data?.page?.CFHOMEAboutMe,
  };
}
export async function getAboutMeHome() {
  let pageData;
  // let seoData; implement SEO

  try {
    pageData = await apolloClient.query({
      query: ABOUT_ME_HOME_EXCERPT,
      fetchPolicy: 'no-cache',
      variables: {
        id: '/about-me',
      },
    });
  } catch (e) {
    console.log(
      `[pages][getPageByUri] Failed to query page data: ${e.message}`
    );
    throw e;
  }

  return {
    page: {
      content: pageData?.data?.page?.content,
      title: pageData?.data.page.title,
      og: pageData?.data?.page?.seo,
    },
    aboutMeFields: pageData?.data?.page?.aboutMeFields,
    resumeFields: pageData?.data?.page?.CFResumeLink,
  };
}

export async function getHomepage() {
  let pageData;
  // let seoData; implement SEO

  try {
    pageData = await apolloClient.query({
      query: HOMEPAGE,
      fetchPolicy: 'no-cache',
      variables: {
        id: '1552', // todo: fix this
      },
    });
  } catch (e) {
    console.log(
      `[pages][getPageByUri] Failed to query page data: ${e.message}`
    );
    throw e;
  }

  return pageData?.data.page;
}

export async function getPageMenu() {
  let menus;
  try {
    menus = await apolloClient.query({
      query: PAGE_MENU,
      variables: {
        id: 'WP Headless', // todo: fix this
      },
    });
  } catch (e) {
    console.log(
      `[pages][getPageByUri] Failed to query page data: ${e.message}`
    );
    throw e;
  }

  return menus?.data?.menu?.menuItems;
}

export async function getFooterDetails() {
  let pageData;
  // let seoData; implement SEO

  try {
    pageData = await apolloClient.query({
      query: FOOTER_DETAILS,
      fetchPolicy: 'no-cache',
      variables: {
        id: '1552',
      },
    });
  } catch (e) {
    console.log(
      `[pages][getPageByUri] Failed to query page data: ${e.message}`
    );
    throw e;
  }

  return {
    availabilityText: pageData?.data?.page?.CFFooterDetails?.availabilityText,
    links: pageData?.data?.page?.CFFooterDetails?.links,
    projects: pageData?.data?.page?.CFFooterDetails?.recentProjects,
  };
}

export async function getContactPage() {
  let page;
  try {
    page = await apolloClient.query({
      query: CONTACT_PAGE,
      variables: {
        id: 'contact-me',
      },
    });
  } catch (e) {
    console.log(
      `[pages][getPageByUri] Failed to query page data: ${e.message}`
    );
    throw e;
  }

  return page?.data?.page;
}

export async function getPathsPosts() {
  let posts;
  try {
    posts = await apolloClient.query({
      query: GET_URLS,
    });
  } catch (e) {
    console.log(
      `[pages][getPathsPosts] Failed to query page data: ${e.message}`
    );
    throw e;
  }
  return [
    ...posts?.data?.projects?.nodes?.map((p) => p.uri),
    ...posts?.data?.businessVentures?.nodes?.map((p) => p.uri),
  ];
}
