import classNames from "classnames";
import React from "react";
import AnimatedBlock from "../AnimatedBlock";
import Section from "../Section";

const ClonnedComponent = (props) => {
  const { children, className = {}, component = "div" } = props;
  const containerClass = classNames(className);

  return React.createElement(
    component,
    { ...props, className: containerClass },
    children
  );
};

const Container = (props) => {
  const { displaySection = true, isAnimated = false } = props;
  return displaySection ? (
    <Section {...props}>
      {isAnimated ? (
        <AnimatedBlock style={props.style}>
          <ClonnedComponent {...props} />
        </AnimatedBlock>
      ) : (
        <ClonnedComponent {...props} style={props.style} />
      )}
    </Section>
  ) : null;
};

export default Container;
