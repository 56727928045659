import React, { useEffect } from "react";
import { useUIStateContext, useUIDispatchContext } from "../UIContext";
import { motion } from "framer-motion";
import classNames from "classnames";

const top = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: 45,
    translateY: 2,
  },
};
const center = {
  closed: {
    opacity: 1,
  },
  opened: {
    opacity: 0,
  },
};
const bottom = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: -45,
    translateY: -2,
  },
};

const ButtonMenu = ({
  width = 24,
  height = 24,
  color = "#fff",
  className = "",
  ...props
}) => {
  const state = useUIStateContext();
  const dispatch = useUIDispatchContext();
  const btnClass = classNames(className, "z-50 relative");

  const handleMenuClick = () => {
    dispatch({
      type: "SET_MENU_STATE",
      payload: !state.isMenuOpen,
    });
  };

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = state.isMenuOpen ? "hidden" : "auto";
    }
  }, [state.isMenuOpen]);

  const variant = state.isMenuOpen ? "opened" : "closed";
  const lineProps = {
    stroke: color,
    strokeWidth: "3",
    vectorEffect: "non-scaling-stroke",
    strokeLinecap: "round",
    initial: "closed",
    animate: variant,
    transition: { type: "spring", stiffness: 260, damping: 20 },
    lineProps: { strokeLinecap: "round" },
  };
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;

  return (
    <motion.svg
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow='visible'
      preserveAspectRatio='none'
      width={width}
      height={height}
      onClick={handleMenuClick}
      className={btnClass}
      {...props}
    >
      <motion.line
        x1='0'
        x2={unitWidth}
        y1='0'
        y2='0'
        variants={top}
        {...lineProps}
      />
      <motion.line
        x1='0'
        x2={unitWidth}
        y1='2'
        y2='2'
        variants={center}
        {...lineProps}
      />
      <motion.line
        x1='0'
        x2={unitWidth}
        y1='4'
        y2='4'
        variants={bottom}
        {...lineProps}
      />
    </motion.svg>
  );
};

export default ButtonMenu;
