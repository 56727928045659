import { useRouter } from "next/router";
import React from "react";
import { HeaderCommon } from "../../Header";

export function PageLayout(props) {
  const route = useRouter();

  return (
    <>
      {route.pathname !== "/" && <div className='h-[80px]' />}
      <HeaderCommon />
      <main className='static'>{props.children}</main>
    </>
  );
}
